<template lang="pug">
.d-flex.align-center.justify-center.flex-column.order-confirm
    h2.text-center(:class="{'error--text': error, 'success--text': success, 'warning--text': already}")
        template(v-if="already") {{$t('orders.order_already_confirmed')}}
        template(v-else-if="success") {{$t('orders.order_confirmed')}}
        template(v-else-if="error") {{$t('orders.order_confirmation_error')}}
        .w-100
        v-btn(:to="toLogin" color="info" dense small) {{ $t('auth.go_to_login') }}
    p.mt-4(v-if="!success && error") {{error}}
</template>

<script>
import store from '@/store/store'
import authType from '@/store/type/authType'
import routeType from '@/router/routeType'
import OrdersPublicApiClient from '@/api/RestApi/OrdersPublicApiClient'
import dayjs from '@/plugins/date'
import { DATE_TIME_FORMAT } from '@/const/DateTimeFormats'

export default {
    data() {
        return {
            success: null,
            already: null,
            error: null,
        }
    },
    computed: {
        toLogin() {
            return { name: routeType.LOGIN }
        },
    },
    beforeRouteEnter(to, from, next) {
        const identifier = to.params.id
        const cutoff = to.query.cutoffTime
        const promises = [OrdersPublicApiClient.approve(identifier)]

        if (cutoff) {
            const cutoffTime = dayjs(decodeURIComponent(cutoff)).format(
                DATE_TIME_FORMAT
            )
            const canteenIdentifier = identifier.substring(
                0,
                identifier.lastIndexOf('-')
            )
            const restaurantId = identifier.substring(
                identifier.lastIndexOf('-') + 1
            )

            promises.push(
                OrdersPublicApiClient.sendCanteenNotiConfirm(
                    restaurantId,
                    canteenIdentifier,
                    cutoffTime
                )
            )
        }

        Promise.all(promises)
            .then((results) => {
                const res = results[0]
                const already = results?.[1]?.success
                    ? false
                    : res.already_approved
                if (store.getters[authType.getters.IS_LOGGED_IN])
                    next({ name: routeType.ORDER_LIST })
                else
                    next((vm) => {
                        vm.$set(vm, 'success', res.success)
                        vm.$set(vm, 'already', already)
                    })
            })
            .catch((e) => {
                if (store.getters[authType.getters.IS_LOGGED_IN])
                    next({ name: routeType.ORDER_LIST })
                else
                    next((vm) => {
                        vm.$set(vm, 'success', false)
                        vm.$set(vm, 'error', e.response?.errors?.message)
                    })
            })
    },
}
</script>

<style scoped lang="scss">
.order-confirm {
    height: calc(100vh - 64px);
}
</style>
